<template>
  <div class="py-md-8 py-6">
    <v-img class="teaser-kitchen-design" v-if="!customerInfoFilled" src="@/assets/images/teaser-kitchen-design.png" />
    <s-kitchen-visualizer v-else />

    <div class="mobile-right-pane">
      <s-contact-details v-if="isVisualizationStepActive && !customerInfoFilled" />
      <s-stone-details v-if="isVisualizationStepActive && customerInfoFilled && selectedStone" />
    </div>

    <div :class="['mt-md-8 mt-6', { 'action-buttons': customerInfoFilled }]">
      <v-btn
        type="button"
        elevation="0"
        :class="['mr-4 rounded-0', {'sm-full-width mr-0': !customerInfoFilled}]"
        outlined
        @click="goToPreviousStep"
        large
      >
        {{ $t("defaults.back") }}
      </v-btn>
      <v-btn
        type="button"
        elevation="0"
        color="primary"
        :disabled="!selectedStone"
        v-if="customerInfoFilled"
        @click="proceedToOrder"
        class="rounded-0"
        large
      >
        {{ $t("createOrder.visualization.actions.proceedToOrder") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import ContactDetails from "@/views/create-order/visualization/ContactDetails.vue";
import StoneDetails from "@/components/create-order/StoneDetails.vue";
import KitchenVisualizer from "./KitchenVisualizer.vue";

export default {
  data() {
    return {
      previousRouteName: null
    };
  },
  components: {
    "s-stone-details": StoneDetails,
    "s-contact-details": ContactDetails,
    "s-kitchen-visualizer": KitchenVisualizer
  },
  computed: {
    ...mapGetters("Customer", ["customerInfoFilled"]),
    ...mapState("Visualization", {
      selectedStone: state => state.selectedStone,
      visualizerImages: state => state.visualizerImages,
      stones: state => state.stones
    }),
    isVisualizationStepActive() {
      return this.$route.name === "visualization";
    }
  },
  methods: {
    ...mapActions("Steps", ["completeStep"]),
    ...mapActions("Visualization", ["getVisualizerImages", "getStones"]),
    proceedToOrder() {
      this.completeStep("visualization");
      this.$router.push("/order-countertop/kitchen-details");
    },
    goToPreviousStep() {
      this.$router.push("/design-countertop/colors/cabinet");
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRouteName = from.name;
    });
  },
  async mounted() {
    if (this.previousRouteName === "colors/cabinet") {
      await this.getVisualizerImages();
      this.getStones();
    }
  }
};
</script>
