<template>
  <div>
    <div class="d-flex flex-md-row flex-column align-center mb-md-5 mb-4">
      <label class="text-subtitle-1 font-weight-medium gray--text text--darken-2 align-self-md-center align-self-start mr-md-5 mr-0 mb-md-0 mb-3">
        {{ $t("createOrder.visualization.stoneSelection.form.stone.label") }}
      </label>
      <v-radio-group @change="handleStoneChange" hide-details class="stone-radio-group mt-md-0 mt-6" row
                     :value="selectedStone.id">
        <template v-for="stone in stones">
          <v-radio active-class="active" class="stone-radio-option" :key="stone.id"
                   :value="stone.id">
            <template v-slot:label>
              <img :src="getAttachmentPreviewURL(stone.slabImage, null, 108)" :alt="stone.displayName" />
            </template>
          </v-radio>
        </template>
      </v-radio-group>
    </div>
    <div ref="visualizerCarousel" :style="{'height': carouselHeight}" class="visualizer-carousel">
      <v-btn @click="toggleCarouselFullScreen" icon class="fullscreen-button theme--dark">
        <v-icon large>{{ isFullScreenMode ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}</v-icon>
      </v-btn>
      <v-carousel
        hide-delimiter-background
        :value="currentCarouselImageIndex"
        @change="handleCarouselChange"
        :height="carouselHeight"
      >
        <v-carousel-item>
          <img :src="getAttachmentPreviewURL(selectedStone.visualizerImage)" />
        </v-carousel-item>

        <v-carousel-item>
          <img :src="getAttachmentPreviewURL(selectedStone.closeUpImage)" />
        </v-carousel-item>

        <v-carousel-item>
          <img :src="getAttachmentPreviewURL(selectedStone.vignetteImage)" />
        </v-carousel-item>

        <v-carousel-item>
          <img :src="getAttachmentPreviewURL(selectedStone.slabImage)" />
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import screenfull from "screenfull";
import { mapActions, mapState } from "vuex";
import { getAttachmentPreviewURL } from "@/utils/attachment";

export default {
  data() {
    return {
      carouselHeight: this.$vuetify.breakpoint.smAndDown ? "220px" : this.$vuetify.breakpoint.mdAndDown ? "354px" : "500px",
      isFullScreenMode: false
    };
  },
  computed: {
    ...mapState("Visualization", {
      stones: state => state.stones,
      visualizerImages: state => state.visualizerImages,
      selectedStone: state => state.selectedStone,
      currentCarouselImageIndex: state => state.currentCarouselImageIndex,
      currentCarouselImageKey: state => state.currentCarouselImageKey
    })
  },
  methods: {
    getAttachmentPreviewURL,
    ...mapActions("Visualization", ["getVisualizerImages", "getStones", "saveSelectedStone", "saveCurrentCarouselImageIndex"]),
    handleStoneChange(stoneId) {
      this.saveSelectedStone(stoneId);
      this.saveCurrentCarouselImageIndex(0);
    },
    toggleCarouselFullScreen() {
      screenfull.toggle(this.$refs.visualizerCarousel);
    },
    handleFullScreenChange() {
      if (screenfull.isFullscreen) {
        this.carouselHeight = this.$vuetify.breakpoint.smAndDown ? "220px" : this.$vuetify.breakpoint.mdAndDown ? "354px" : "100vh";
        this.isFullScreenMode = true;
      } else {
        this.carouselHeight = this.$vuetify.breakpoint.smAndDown ? "220px" : this.$vuetify.breakpoint.mdAndDown ? "354px" : "500px";
        this.isFullScreenMode = false;
      }
    },
    handleCarouselChange(index) {
      this.saveCurrentCarouselImageIndex(index);
    }
  },
  mounted() {
    screenfull.on("change", this.handleFullScreenChange);
  },
  beforeDestroy() {
    screenfull.off("change", this.handleFullScreenChange);
  }
};
</script>